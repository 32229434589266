import React from "react";
import Staking from "../components/Staking";

const Home = () => {
  return (
    <div
      className="min-h-screen"
      style={{
        background: "linear-gradient(to right, rgba(20, 35, 35, 0.7), rgb(20, 35, 35), rgba(20, 35, 35, 0.7)) rgb(0, 0, 0)"
      }}
    >
      <Staking />
    </div>
  );
};

export default Home;
