import React, { useEffect, useState } from "react";
import { useAccount, useContractRead } from "wagmi";
import { abi } from "./contract";

const EarnedRewards = () => {
  const [earned, setEarned] = useState("");
  const { address } = useAccount();
  const { data, isRefetching, refetch } = useContractRead({
    address: `0x${process.env.REACT_APP_STAKING_ADDRESS}`,
    abi,
    functionName: "earned",
    args: [address],
    enabled: true,
    cacheOnBlock: true,
    watch: true,
  });

  useEffect(() => {
    if (data) {
      const earnedAmount = data.toString(); // Convert BigNumber to string for display
      console.log("earned : ", earnedAmount);

      setEarned(earnedAmount);
    }
  }, [data]);
  // Handle loading and refetching states
  if (isRefetching) return <div>Refetching data...</div>;
  return <span className="text-[#fff]">{earned ? ` ${earned}` : "0"}</span>;
};

export default EarnedRewards;
