"use client";
import { useAccount } from "wagmi";
import { useWeb3Modal } from "@web3modal/wagmi/react";

export const shortText = (text: string, n: number) => {
  if (text.length > n) {
    const shortenedText = text.substring(0, n).concat("...");
    return shortenedText;
  }
  return text;
};

export function Connect() {
  const { open } = useWeb3Modal();
  const { isConnected, address } = useAccount();

  return (
    <div>
      <div>
        {isConnected ? (
          <button
            className="text-center border-[2px] border-[#ffd400] rounded-[20px] p-[10px_12px]"
            onClick={() => open()}
          >
            {shortText(address!, 5)}
          </button>
        ) : (
          <button
            className="text-center border-[2px] border-[#ffd400] rounded-[20px] p-[10px_15px]"
            onClick={() => open()}
          >
            Connect
          </button>
        )}
      </div>
    </div>
  );
}
