import React from "react";
import { useContractWrite, usePrepareContractWrite } from "wagmi";
import { abi } from "./contract";

const Unstake = () => {
  const { config } = usePrepareContractWrite({
    address: `0x${process.env.REACT_APP_STAKING_ADDRESS}`,
    abi,
    functionName: "unstake",
    args: [10],
  });

  const { data, isLoading, isSuccess, write } = useContractWrite(config);

  return (
    <div>
      <button
        disabled={!write}
        className="bg-[#29a9e0] p-[12px_16px]  rounded-[20px]"
        onClick={() => write?.()}
      >
        WITHDRAW STAKED TOKENS
      </button>
      {isLoading && <div>Unstaking</div>}
      {isSuccess && <div>Transaction: {JSON.stringify(data)}</div>}
    </div>
  );
};

export default Unstake;
