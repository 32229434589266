import React, { useEffect, useState } from "react";
import { useAccount, useContractRead } from "wagmi";
import { abi } from "./contract";
import BigNumber from 'bignumber.js';

// interface StakerInfo {
//     stakedAmount: Number;
//     stakingStartTime: BigNumber;
//     lastClaimTime: BigNumber;
//     rewards: BigNumber;
//     vestingPeriod: BigNumber;
//   }
const StakedBal = () => {
    const [bal, setBal] = useState("");
  const { address } = useAccount()
  const { data, isRefetching, refetch } = useContractRead({
    address: `0x${process.env.REACT_APP_STAKING_ADDRESS}`,
    abi,
    functionName: "stakers",
    args : [address],
    enabled: true,
    cacheOnBlock: true,
    watch: true,
  });

  useEffect(() => {
    if (data) {  
      const amount =(data as BigNumber[])[0].toString(); // Convert BigNumber to string for display
      setBal(amount);
    }
  }, [data]);

  if (isRefetching) return <div>Refetching data...</div>;

  return (
    <span className="text-[#fff]">
      {bal ? ` ${bal}` : "0"}
    </span>
  )
}

export default StakedBal