import React from "react";
import { Connect } from "./Connect";

const Header = () => {
  return (
    <div className="">
      <div className=" px-[20px] ">
        <div className=" flex justify-between sm:mx-0 mx-5 text-[#ffffff] items-center py-[15px] ">
           <img src='/logo.png' alt='logo' width={50}/>
          <div className="  text-[#ffffff] text-center rounded-[4px]">
            <Connect />
            {/* <WalletMultiButton style={{ padding:'10px', lineHeight: 'inherit' }}  /> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
