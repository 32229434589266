import { FC, ReactNode } from "react";
import { WagmiConfig, configureChains, createConfig } from "wagmi";
import { celoAlfajores, goerli } from "wagmi/chains";
import { infuraProvider } from "wagmi/providers/infura";
import { jsonRpcProvider } from "wagmi/providers/jsonRpc";
import { WalletConnectConnector } from "wagmi/connectors/walletConnect";
import { EIP6963Connector, createWeb3Modal } from "@web3modal/wagmi/react";
import { InjectedConnector } from "wagmi/connectors/injected";
import { CoinbaseWalletConnector } from "wagmi/connectors/coinbaseWallet";

// Specify the JSON RPC URL for the Alfajores testnet
const alfajoresRpcUrl = "https://alfajores-forno.celo-testnet.org";
const goerliRpcUrl = "https://ethereum-goerli.publicnode.com";

const { chains, publicClient, webSocketPublicClient } = configureChains(
  [celoAlfajores, goerli],
  [
    infuraProvider({ apiKey: "a2047ea7526243caa61bdfda4b24e4d5" }),
    jsonRpcProvider({
      rpc: (chain) => {
        if (chain.id === celoAlfajores.id) {
          return {
            http: alfajoresRpcUrl,
            webSocket: `wss://alfajores-forno.celo-testnet.org/ws`,
          };
        }
        // Check for Goerli
        else if (chain.id === goerli.id) {
          return {
            http: goerliRpcUrl, // Use the Goerli RPC URL
            // Optionally, specify a WebSocket URL for Goerli if you have one
          };
        }
        // You can specify additional RPC URLs for other chains here
        return { http: "https://alfajores-forno.celo-testnet.org" };
      },
    }),
  ]
);

const projectId = "723ab3fce192e6f90f48d05bf3516a0f";

const metadata = {
  name: "Web3Modal",
  description: "Web3Modal Example",
  url: "https://web3modal.com",
  icons: ["https://avatars.githubusercontent.com/u/37784886"],
};

const wagmiConfig = createConfig({
  autoConnect: true,
  connectors: [
    new WalletConnectConnector({
      chains,
      options: { projectId, showQrModal: false, metadata },
    }),
    new EIP6963Connector({ chains }),
    new InjectedConnector({ chains, options: { shimDisconnect: true } }),
    new CoinbaseWalletConnector({
      chains,
      options: { appName: metadata.name },
    }),
  ],
  publicClient,
  webSocketPublicClient,
});

createWeb3Modal({ wagmiConfig, projectId, chains });

interface WagmiProviderProps {
  children: ReactNode;
}

export const WagmiProvider: FC<WagmiProviderProps> = ({ children }) => {
  return <WagmiConfig config={wagmiConfig}>{children}</WagmiConfig>;
};
