import React from "react";
import { useContractWrite, usePrepareContractWrite } from "wagmi";
import { abi } from "./contract";

const ClaimReward = () => {
  const { config } = usePrepareContractWrite({
    address: `0x${process.env.REACT_APP_STAKING_ADDRESS!}`,
    abi,
    functionName: "claimReward",
  });

  const { data, isLoading, isSuccess, write } = useContractWrite(config);

  return (
    <div>
      <button
        disabled={!write}
        className="text-center border-[2px] border-[#ffd400] rounded-[20px] p-[14px_12px]"
        onClick={() => write?.()}
      >
        CLAIM REWARDS
      </button>
      {isLoading && <div>Claiming</div>}
      {isSuccess && <div>Transaction: {JSON.stringify(data)}</div>}
    </div>
  );
};

export default ClaimReward;
